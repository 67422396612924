
/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */

define('modules/Dialer/models/Dialer_Record_Model',[
    'jquery',
    'underscore',
    'backbone',
], function($, _, Backbone) {

    /**
     * @class Dialer_Record_Model
     *
     * Backbone.Model
     * Redord de un dialer para hacer una llamada
     */
    var Dialer_Record_Model = Backbone.Model.extend({
        TAG: 'Dialer_Record_Model',
        appmodel: null,
        rpeer: null,
        epeer: null,
        
        /**
         * Dialer_Record_Model::defaults
         * Objeto de parametros por defecto
         */
        defaults: {
            // @note - Peer id del emisor
            emit            : 0,
            // @note - Peer id del receptor
            receive         : 0,
            // @note - Password del emiso si lo tiene
            passroom        : '',
            // @note - Estado de la marcacion
            status          : '',
            // @note - Denota el tipo de llamada
            type            : 'stream',
        },
        
        /**
         * Dialer_Record_Model.url
         * Url en donde se solicitara la llamada
         * @return <String> url - ruta en el servidor del modulo
         */         
        url: function() {
            var self = this;
            return self.appmodel.url()+'/dialers'+(self.isNew() ? '' : '/'+self.id);
        },
        
        /**
         * Dialer_Record_Model.initialize
         * Constructor del modelo para llamadas, solo acepta atributos definidos
         * @param <Object> atributes - Atributos de la vista
         * @param <Object> options - {appmodel: App_Base_Model}
         */
        initialize: function(attributes, options) {
            var self = this;
            void 0;
            self.appmodel = options.appmodel;
            self.epeer = self.appmodel.peers.get(self.get('emit'));
            self.rpeer = self.appmodel.peers.get(self.get('receive'));
            void 0;
        },
        
        /**
         * Dialer_Record_Model.reply
         * Contesta la llamada
         * @emisor
         */
        reply: function() {
            var self = this;
            void 0;
            // @note - Asegura de que los dos peer esten cargados
            self.appmodel.peers.getModel({id: self.get('emit')}, function(error, emit) {
                self.appmodel.peers.getModel({id: self.get('receive')}, function(error, receive) {
                    receive.reply();
                });
            });
            void 0;
        },
        
        /**
         * Dialer_Record_Model.reject
         * Rechaza la llamada
         * @emisor
         */
        reject: function() {
            var self = this;
            void 0;
            self.save({'status': 'reject'}, {patch: true});
            void 0;
        },
        
        /**
         * Dialer_Record_Model.call
         * Realiza una llamada, es enviado al peer suplier
         * @receiver
         */
        call: function() {
            var self = this;
            void 0;
            self.epeer.call(self.attributes);
            void 0;
        },
        
        /**
         * Dialer_Record_Model.discard
         * Descarta la llamada
         * @receiver
         */
        discard: function() {
            var self = this;
            void 0;
            self.save({status: 'discard'}, {patch: true});
            void 0;
        },
        
        /**
         * Dialer_Record_Model.fields
         * @brief Array de campos para graficar
         * @param <Peer_Base_Model> peer - Modelo para leer campos
         * @return <Array> fields - Campos para el model
         */
         
        fields: function(peer) {
            var self = this;
            void 0;
            var fields = [
                {   'name'              : 'image',
                    'type'              : 'picture',
                    'attachment'        : {
                        res_id          : peer.id,
                        res_model       : 'res.partner',
                        name            : 'image',
                        default_file    : 'images/guest.png'
                    },
                    'data-module'       : 'Dialer',
                    'data-label'        : false,
                    'data-disabled'     : true,
                    'data-value-colspan': 2
                },
                {   'name'              : 'username',
                    'type'              : 'text',
                    'value'             : peer.get('username'),
                    'data-module'       : 'Dialer',
                    'data-label'        : false,
                    'data-disabled'     : true,
                    'data-value-colspan': 2
                },
                {   'name'              : 'passroom',
                    'type'              : 'password',
                    'value'             : '',
                    'data-module'       : 'Dialer',
                    'data-bv-notempty'  : false,
                    'data-label'        : false,
                    'data-value-colspan': 2
                },
                {   'name'              : 'ansafone',
                    'type'              : 'checkbox',
                    'value'             : 'ansafone',
                    'data-module'       : 'Dialer',
                    'data-checked'      : peer.get('ansafone'),
                },
            ];
            void 0;
            return fields;
        },
    },  /*static*/ {

        type: {
            // chat - las llamada tipo chat establece un canal de datos
            CHANNEL : 'channel',
            // stream - pide autorizacion al clientes y solicita un stream
            STREAM  : 'stream',
        }

    });
    
    return Dialer_Record_Model;
    
});

/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */

define('modules/Dialer/collections/Dialer_Model_Collection',[
    'jquery',
    'underscore',
    'backbone',
    'modules/Dialer/models/Dialer_Record_Model'
], function($, _, Backbone, Dialer_Record_Model) {

    /**
     * @class Dialer_Model_Collection
     *
     * Backbone.Collection
     * Guarda todos los call que estan pendientes de respuesta, cola de llamadas 
     */
    
    var Dialer_Model_Collection = Backbone.Collection.extend({
        model: Dialer_Record_Model,
        appmodel: null,
        
        /**
         * Dialer_Model_Collection.url
         * Url en el servidor para colleccion de marcaciones
         * @return <String> url - ruta en el servidor
         */
        
        url: function() {
            var self = this;
            return self.appmodel.url()+'/dialers';
        }, 
        
        /**
         * Dialer_Model_Collection.initialize
         * Constructor de la cola de marcaciones
         * @param <Array> models - Modelos para ser agregados a la coleccion
         * @param <Object> options - debe contener appModel, opciones para la colleccion 
         */
         
        initialize: function(models, options) {
            var self = this;
            self.appmodel = options.appmodel;
        },
        
        /**
         * Dialer_Model_Collection.getModel
         * Obtiene un modelo segun la condiciones pasadas
         * @param <Object> properties - Atributos para ser buscados en la collecion
         * @param <function> callback - Funcion para ser llamada despues de la busqueda
         */
        
        getModel: function(properties, callback) {
            var self = this;
            var _model_ = self.findWhere(properties);
            if(!_.isUndefined(_model_)) {
                // @note - No necesita buscar en el servidor por records
                callback(null, _model_);
            } else {
                callback(true, null);
            }
        },
        
    }, {

        /**
         * Dialer_Model_Collection.getRawModel
         * Obtiene un nuevo modelo segun los properties pasadas,
         * este no es agregado a la colleccion
         * @param <Object> properties - atributos para el nuevo modelo
         * @return <Dialer_Record_Model> rawmodel - retorna el nuevo modelo
         */
        getRawModel: function(properties, options) {
            return new Dialer_Record_Model(properties, options);
         }

    });
    
    return Dialer_Model_Collection;

});

define('include/requirejs-text/text!modules/Dialer/views/Dialer_Index_View.html',[],function () { return '';});

define('include/require-css/css!modules/Dialer/views/Dialer_Index_View',[],function(){});
/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */

define('modules/Dialer/views/Dialer_Index_View',[
    'jquery',
    'underscore',
    'backbone',
    'modules/Dialer/collections/Dialer_Model_Collection',
    'include/requirejs-text/text!./Dialer_Index_View.html',
    'include/require-css/css!./Dialer_Index_View.css',
], function(
        $,
        _,
        Backbone,
        Dialer_Model_Collection,
        Template
    ) {

    /**
     * @class Dialer_Index_View
     *
     * Backbone.View
     * Carga una ventana para solicitar el password y ver que este marcando al peer.
     * Ver el diagrama WebRTC.dia en los documentos para enterder el funcionamiento
     * de como esta disenado el proceso de llamada y respuesta.
     */
    var Dialer_Index_View = Backbone.View.extend({
        TAG: 'Dialer_Index_View',
        model: null,
        appmodel: null,
        template: null,
        dialers: null,
        
        /**
         * Channel_Stream_View::wrapper
         * Optiones para graficar la vista
         */
        _template_: {
            html    : Template,
            name    : '#dialer-index-template'
        },
        
        /**
         * Dialer_Index_View::events
         * Objeto de los eventos para la vista
         */
        
        events: {
            
        },
        
        /**
         * Dialer_Index_View.initialize
         * Constructor de la ventana de llamada 
         * @param <Object> attributes - Atributos de la vista
         * @param <App_Record_Model> appmodel - Modelo de la aplicacion
         */
         
        initialize: function(attributes, appmodel) {
            var self = this;
            void 0;
            self.appmodel = appmodel;
            self.dialers = new Dialer_Model_Collection(null, {appmodel: self.appmodel});
            // @note - Obtiene una llamada entrante para el supplier peer y solo responde si esta en modo stream
            self.appmodel.peers.on('on.get.dialer.rse', function(dialer) {
                var epeer = self.appmodel.peers.getSupplier();
                if(epeer.get('status')===epeer.constructor.status.STREAM) {
                    self.dialers.add(dialer, {appmodel: self.appmodel});
                }
            });
            // @note - Vigila los nuevos peers y hace una llamada si esta en status STREAM
            self.appmodel.peers.on('add', function(model) {
                if(model.get('status')===model.constructor.status.STREAM) {
                    var rpeer = self.appmodel.peers.getSupplier();
                    self.dialers.add({emit: model.id, receive: rpeer.id}, {appmodel: self.appmodel});
                }
            });
            // @note - Vigila si hay algun cambio en el estado de los peers remotos y hace una llamada si esta en modo STREAM
            self.appmodel.peers.on('change:status', function(model) {
                if(model.get('supplier')) return;
                if(model.get('status')===model.constructor.status.STREAM) {
                    var rpeer = self.appmodel.peers.getSupplier();
                    self.dialers.add({emit: model.id, receive: rpeer.id}, {appmodel: self.appmodel});
                }
            });
            self.dialers.on('add', self.onAdd, self);
            void 0;
        },
        
        /**
         * Dialer_Index_View.onAdd
         * La funcion se encarga de mapear un Product_Record_Model a una vista
         * @param <Product_Record_Model> model - Modelo para renderizar una vista
         * @private
         */
         onAdd: function(model) {
            var self = this;
            void 0;
            // @debug - console.info('>>>>>>>', JSON.stringify(model.attributes));
            if(model.epeer.get('supplier')) {
                model.reply();
            } else {
                model.call();
            }
            void 0;
        },
        
    });
    
    return Dialer_Index_View;
    
});

/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */

define('modules/Dialer/__init__',[
    'jquery',
    'underscore',
    'backbone',
    './views/Dialer_Index_View'
], function($, _, Backbone, Dialer_Index_View) {
    
    return Dialer_Index_View;
    
});
